import React, { useEffect } from 'react';

export default function Home() {
  useEffect(() => {
    // Delay for 1 second before redirecting
    const timer = setTimeout(() => {
      // Redirect to the specified URL after the delay
      window.location.href = 'https://www.gu-wi.com/';
    }, 1000); // 1000 milliseconds = 1 second

    // Clear the timer when the component unmounts to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-green-800 min-h-screen flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-2xl font-bold mb-4">
          Redirecting to <a href="https://www.gu-wi.com/" className="text-blue-700 hover:underline">www.gu-wi.com</a>...
        </p>
      </div>
    </div>
  );
}
