import React from 'react';

function ContactUsSection() {
    return (
        <section className="bg-slate-100 py-12">
            <div className="container mx-auto px-4">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-slate-800 capitalize">
                        Having trouble? Contact us for immediate assistance:
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Call us: <a href="tel:833-438-4894" className="text-blue-500">833-438-4894</a> |
                        Text us: <a href="sms:206-339-5440" className="text-blue-500">206-339-5440</a> |
                        Email us: <a href="mailto:support@gu-wi.com" className="text-blue-500">support@gu-wi.com</a>
                    </p>
                    <p className='mt-4 text-gray-600'>
                        Engineered by <a href="https://www.upwork.com/freelancers/jatindahiya" className='text-blue-500' target='_blank' rel="noreferrer">Jatin Dahiya</a> & Powered by <a href="https://www.thewebnavigators.com/" className='text-blue-500' target='_blank' rel="noreferrer">The Web Navigators</a>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ContactUsSection;
