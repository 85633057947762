import React, { useState, useEffect } from 'react';

function Toast({ message }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setIsVisible(true);

            // Hide the toast after 2 seconds (2000 milliseconds)
            setTimeout(() => {
                setIsVisible(false);
            }, 3000);
        }
    }, [message]);

    return (
        <div className={`fixed top-0 left-0 right-0 p-4 flex justify-center transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            {isVisible && (
                <div className="bg-gray-800 text-white p-3 rounded-lg shadow-lg">
                    {message}
                </div>
            )}
        </div>
    );
}

export default Toast;
