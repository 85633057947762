import React from 'react';
import PropTypes from 'prop-types';

const AddServices = ({ Services, onAddService }) => {
    return (
        <div className='bg-white p-2 md:p-4 rounded-lg shadow mb-4'>
            {/* Services */}
            <h3 className="font-bold text-md text-slate-800 mb-4 inline-flex gap-1 items-center">
                <span>Service Available For Your Property</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-.53 14.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V8.25a.75.75 0 00-1.5 0v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clipRule="evenodd" />
                </svg>
            </h3>
            <div className="overflow-x-scroll md:overflow-none hide-scrollbar">
                <div className="w-screen md:w-full flex items-center flex-row md:flex-col gap-3 hide-scrollbar">
                    {Services.map((service) => (
                        <ServiceCard key={service.serviceName} service={service} onAddService={onAddService} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const ServiceCard = ({ service, onAddService }) => {
    const handleAddService = () => {
        onAddService(service, service.serviceCategory);
    };

    return (
        <div className="w-3/4 shrink-0 md:w-full">
            <div className="bg-cover bg-center relative rounded-lg overflow-hidden">
                <div className="bg-opacity-50 bg-black absolute inset-0"></div>
                <img
                    src={service.imgUrl}
                    alt=''
                    className="object-cover w-full h-56 rounded-lg hover:scale-105 transition-transform duration-300"
                    loading="lazy" // Lazy loading for images
                />
                <div className="absolute inset-0 p-2 flex flex-col justify-end">
                    <h2 className="text-white text-lg font-semibold mb-2">{service.serviceName}</h2>
                    <div className="flex justify-between items-center text-white">
                        <span className="text-lg font-bold">$ {service.oneTimePrice}</span>
                        <button
                            className="bg-green-800 hover:bg-green-600 text-lg font-semibold p-2 rounded-lg md:rounded-full flex items-center space-x-2"
                            onClick={handleAddService}
                        >
                            Add Service
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 ml-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

AddServices.propTypes = {
    Services: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddService: PropTypes.func.isRequired,
};

ServiceCard.propTypes = {
    service: PropTypes.object.isRequired,
    onAddService: PropTypes.func.isRequired,
};

export default AddServices;
