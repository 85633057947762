import React, { useState, useEffect } from 'react';

function QuoteDisplay({ quote, onRemoveService }) {

    const [buttonText, setButtonText] = useState(() => {
        const requestStatus = localStorage.getItem('quoteRequestStatus');
        if (requestStatus) {
            const statusObj = JSON.parse(requestStatus);
            if (statusObj[quote.planType]) {
                return 'Request Sent';
            }
        }

        return quote.planType === 'One-Time'
            ? `Request This One-Time Work Order For $${Math.round(quote.finalAmount)}`
            : `Request This Service Plan For $${Math.round(quote.finalAmount / quote.totalInstallments)} per month`;
    });

    const [buttonDisabled, setButtonDisabled] = useState(() => {
        const requestStatus = localStorage.getItem('quoteRequestStatus');
        if (requestStatus) {
            const statusObj = JSON.parse(requestStatus);
            return statusObj[quote.planType] === true;
        }
        return false;
    });

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    const sendQuoteRequest = async (quote) => {
        try {
            if (!buttonDisabled) {
                setButtonText('Sending...');
                const url = 'https://guwiquotebuilder.com/backend/send-quote-request.php';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ quote }),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setButtonText('Request Sent');
                    setButtonDisabled(true);

                    // Update the request status in localStorage
                    const requestStatus = localStorage.getItem('quoteRequestStatus');
                    const statusObj = requestStatus ? JSON.parse(requestStatus) : {};
                    statusObj[quote.planType] = true;
                    localStorage.setItem('quoteRequestStatus', JSON.stringify(statusObj));
                    // Show the confirmation pop-up
                    setShowConfirmationPopup(true);
                } else {
                    console.error('Failed to send the request');
                    setButtonText(
                        quote.planType === 'One-Time'
                            ? `Request This One-Time Work Order For $${Math.round(quote.finalAmount)}`
                            : `Request This Service Plan For $${Math.round(quote.finalAmount / quote.totalInstallments)} per month`
                    );
                    alert('Failed to send the request.');
                }
            } else {
                alert('Request already sent.');
            }
        } catch (error) {
            console.error(error);
            setButtonText(
                quote.planType === 'One-Time'
                    ? `Request This One-Time Work Order For $${Math.round(quote.finalAmount)}`
                    : `Request This Service Plan For $${Math.round(quote.finalAmount / quote.totalInstallments)} per month`
            );
            alert('An error occurred while sending the request.');
        }
    };

    const handleRemoveService = (service) => {
        onRemoveService(service);
    };

    useEffect(() => {
        const requestStatus = localStorage.getItem('quoteRequestStatus');
        if (requestStatus) {
            const statusObj = JSON.parse(requestStatus);
            if (statusObj[quote.planType]) {
                setButtonText('Request Sent');
            } else {
                setButtonText(
                    quote.planType === 'One-Time'
                        ? `Request This One-Time Work Order For $${Math.round(quote.finalAmount)}`
                        : `Request This Service Plan For $${Math.round(quote.finalAmount / quote.totalInstallments)} per month`
                );
            }
        } else {
            setButtonText(
                quote.planType === 'One-Time'
                    ? `Request This One-Time Work Order For $${Math.round(quote.finalAmount)}`
                    : `Request This Service Plan For $${Math.round(quote.finalAmount / quote.totalInstallments)} per month`
            );
        }
    }, [quote]);

    return (
        <div className='container bg-white p-2 md:p-4 rounded-2xl'>
            <div className='flex flex-col items-center mb-4 gap-2'>
                <h2 className='text-center md:text-left font-extrabold text-3xl text-green-800'>
                    {quote.planType === 'One-Time' ? 'One Time Service' : 'Recurring Service Maintenance Plan'}
                </h2>
                {quote.planType !== 'One-Time' && (
                    <p className='text-stone-800 mt-2 mb-2'>Maintenance Plans are Based on {quote.planDuration} Years of Service with {quote.totalInstallments} Monthly Payments.</p>
                )}
                <p>
                    <span className='text-slate-800 font-bold text-xl'>Pricing</span>&nbsp;
                    <span className='text-green-800 font-bold text-xl'>
                        ${Math.round(quote.finalAmount / quote.totalInstallments).toLocaleString()} {quote.planType !== 'One-Time' ? '/month' : ''}
                    </span>
                </p>
            </div>

            <table className="w-full">
                <tbody>
                    {quote.services.map((service, index) => (
                        <tr key={index} className="p-4 md:p-6 bg-slate-100 rounded-2xl grid grid-cols-1 md:grid-cols-2 items-center mb-4 gap-6">
                            <td>
                                <p className="inline-flex gap-1 font-bold text-xl text-slate-800">
                                    <span className="h-6 w-6 bg-black text-white text-sm rounded-full flex items-center justify-center">
                                        {index + 1}
                                    </span>
                                    &nbsp;{service.serviceName}
                                </p>
                                <p className="mt-2 text-md text-slate-600 text-justify">{service.customDescription}</p>
                            </td>
                            <td className="flex flex-row justify-between">
                                <p className="text-md text-slate-600">
                                    {quote.planType === 'One-Time'
                                        ? service.oneTimeServiceQuota + ' Service'
                                        : service.recurringServiceQuota > 1
                                            ? service.recurringServiceQuota + ' Annual Services'
                                            : service.recurringServiceQuota + ' Annual Service'}
                                </p>

                                {quote.planType === 'One-Time' ? (
                                    <p className="text-md text-slate-600">
                                        ${service.oneTimePrice}
                                    </p>

                                ) : (
                                    <p className="text-md text-slate-600">
                                        <del>${Math.round(service.oneTimePrice)}</del>&nbsp;&nbsp;
                                        ${Math.round(service.oneTimePrice * (1 - quote.totalDiscount))}/service
                                    </p>
                                )}

                                <span className='flex items-center gap-1 text-red-600 cursor-pointer' onClick={() => handleRemoveService(service)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-red-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </span>
                            </td>
                        </tr>
                    ))}

                    {
                        (quote.perks && quote.perks.length > 0) ?
                            <tr className='p-4 md:p-6 rounded-2xl grid grid-cols-1 md:grid-cols-2 items-center mb-4 gap-2'>
                                <td className='capitalize font-bold text-xl text-slate-800'>
                                    This plan is eligible for the following benefits.
                                </td>
                            </tr> : <></>
                    }

                    {/* Perks Available */}
                    {quote.perks && quote.perks.map((perk, index) => (
                        <tr key={index} className={`${perk.isActive ? 'bg-slate-100 cursor-pointer' : 'bg-slate-400 cursor-not-allowed'} p-4 md:p-6 rounded-2xl grid grid-cols-1 md:grid-cols-2 items-center mb-4 gap-2`}>

                            <td>
                                <span className="flex items-center gap-2">
                                    {
                                        perk.isActive ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                            </svg>
                                    }
                                    <p className="font-bold text-xl text-slate-800">{perk.perkName}</p>
                                </span>
                                <p className="mt-2 text-md text-slate-600">{perk.perkDescription}</p>
                            </td>

                            <td className="text-end font-bold text-md text-slate-800">
                                {perk.perkDiscount === 0 ? "FREE" : (perk.perkDiscount * 100) + '%'}
                            </td>

                        </tr>
                    ))}

                    <tr className="grid grid-cols-1 md:grid-cols-1 items-center mt-8 mb-8">
                        <td className="flex flex-col items-center md:items-end">
                            {quote.planType !== 'One-Time' && (
                                <span className="mb-4 text-lg text-center md:text-end">
                                    Was&nbsp;
                                    <del className="text-red-500">
                                        ${Math.round(quote.totalAmount).toLocaleString()}
                                    </del>&nbsp;Now Only ${Math.round(quote.finalAmount).toLocaleString()}
                                    <br />
                                    <b>With {quote.totalDiscount * 100}% OFF &amp; {quote.totalInstallments}  Monthly Payments</b>
                                </span>
                            )}
                            <button
                                className="bg-green-800 hover:bg-green-600 p-6 rounded-lg md:rounded-full capitalize font-bold text-xl text-white"
                                onClick={() => sendQuoteRequest(quote)}
                                disabled={buttonDisabled}
                            >
                                {buttonText}
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>

            {/* Confirmation Pop-up */}
            {showConfirmationPopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50 p-6">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="relative bg-white p-4 md:p-8 rounded-lg shadow-lg flex flex-col gap-2 items-center max-w-[300px]">
                        {/* Confirmation Pop-up */}
                        <p className="text-lg md:text-xl text-center font-bold text-green-800">
                            Your Work Order has been sent to our staff
                        </p>
                        <p className="text-md text-center text-slate-800 mb-4">
                            We will contact you very soon to schedule your service and explain the next steps. You can now exit this page.
                        </p>
                        <button
                            className="bg-green-800 text-white font-bold py-2 px-4 rounded-full uppercase hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400"
                            onClick={() => setShowConfirmationPopup(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default QuoteDisplay;
