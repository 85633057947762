import React from 'react'
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import NotFound from './components/NotFound';
import Dashboard from './components/Dashboard';
import Home from './pages/Home'

export default function App() {
  return (
    <Router basename='/'>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/:submissionID' element={<Dashboard />} />
        {/* Add a route for unmatched paths */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  )
}

